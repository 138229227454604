<template>
  <b-col>
    <form-wizard
      color="#F8AC18"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Enviar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="vertical-steps steps-transparent vue-form-wizard-deliberation-broker vertical-steps-deliberation-broker mb-3"
      @on-complete="validationForm">
      <!-- data -->
      <tab-content title="Detalhes da Conta" icon="feather icon-user">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon icon="UserIcon" size="18" />
                  <h4 class="mb-0 ml-75">Dados Pessoais</h4>
                </div>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label="Corretor ou Imobiliária"
                  label-for="account-typePersonName">
                  <b-form-input
                    v-model="registerData.typePersonName"
                    disabled />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="CPF / CNPJ"
                  label-for="account-numberCpfCnpj">
                  <b-form-input v-model="registerData.numberCpfCnpj" disabled />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="Email" label-for="account-email">
                  <b-form-input v-model="registerData.emailAddress" disabled />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label="Nome Completo / Razão Social"
                  label-for="account-document">
                  <b-form-input
                    v-model="registerData.typePersonName"
                    disabled />
                </b-form-group>
              </b-col>
              <b-col v-if="registerData.typePerson === 'F'" sm="6">
                <b-form-group
                  label="Data de Nascimento"
                  label-for="account-document">
                  <b-form-input v-model="registerData.birthDate" disabled />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="Celular" label-for="account-document">
                  <b-form-input v-model="registerData.phoneNumber" disabled />
                </b-form-group>
              </b-col>
              <b-col v-if="registerData.typePerson === 'F'" sm="6">
                <b-form-group
                  label="Documento de Identificação"
                  label-for="account-document">
                  <b-form-input v-model="registerData.numberRg" disabled />
                </b-form-group>
              </b-col>
              <b-col v-if="registerData.typePerson === 'F'" sm="6">
                <b-form-group
                  label="Região de Atuação"
                  label-for="account-document">
                  <b-form-input v-model="registerData.cityOperation" disabled />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
      </tab-content>

      <!-- attachments -->
      <tab-content title="Documentos" icon="feather icon-file-text">
        <b-row>
          <b-col cols="12">
            <b-row class="mb-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon icon="UserIcon" size="18" />
                  <h4 class="mb-0 ml-75">Documentos</h4>
                </div>
              </b-col>
              <ul class="detailing__grid">
                <li
                  v-for="(attachment, key) in attachments"
                  :key="key"
                  class="detailing__document-card">
                  <div v-if="attachment.bytes">
                    <b-modal
                      :id="attachment.id"
                      ok-only
                      ok-variant="secondary"
                      ok-title="Fechar"
                      modal-class="modal-secondary"
                      centered
                      :title="attachment.nameImg">
                      <b-card-text class="text-center detailing__dp-center">
                        <img :src="attachment.srcImg" alt="Image 1')">
                      </b-card-text>
                    </b-modal>
                    <b-link class="detailing__cursor" :to="{ name: 'new-page' }" target="_blank" @click="setDataFile({id: userId, fileKey: attachment.fileKey})">
                      <img
                        class="mt-2"
                        src="~@/assets/images/icons/doc.png"
                        alt="document icon">
                    </b-link>
                  </div>
                  <div v-else>
                      <img
                        width="33px;"
                        class="mt-2"
                        src="~@/assets/images/icons/unknown.png"
                        alt="document icon">
                  </div>
                  <h5 class="mt-2">{{ attachment.nameImg }}</h5>
                  <b-form-select
                    v-if="attachment.bytes"
                    v-model="registerData[attachment.selectedName]"
                    :options="selectOptions" />
                  <h6 v-else class="mt-2">Sem documentos</h6>
                </li>
              </ul>
            </b-row>
          </b-col>

          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
      </tab-content>

      <!-- data send -->
      <tab-content title="Feedback" icon="feather icon-file-text">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon icon="Edit3Icon" size="18" />
                  <h4 class="mb-0 ml-75">Feedback</h4>
                </div>
              </b-col>
              <b-col>
                <div class="detailing__text-area">
                  <quill-editor
                    v-model="registerData.content"
                    :options="snowOption" />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3" class="mb-2 mt-2">
            <b-form-group label="Status" label-for="account-document">
              <b-form-select
                v-model="registerData.selectedFeedback"
                :options="selectOptionsFeedback" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </b-col>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions,no-unused-vars
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  VBModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import Moment from 'moment/moment'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    quillEditor,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    Portuguese,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    // eslint-disable-next-line vue/no-unused-components
    Moment,
  },
  data() {
    return {
      userId: this.$route.params.id,
      id: '',
      registerData: {
        typePersonName: '',
        typePerson: 'F',
        name: '',
        surname: '',
        userName: '',
        emailAddress: '',
        phoneNumber: '',
        numberCpfCnpj: '',
        numberRg: '',
        birthDate: '',
        creci: '',
        cityOperation: '',
        content: '',
        situation: null,

        selectedCreci: '',
        selectedFaceCreci: '',
        selectedDocument: '',
        selectedFaceDocument: '',
        selectedFeedback: '',
      },

      attachments: [],

      selectOptions: [
        { value: 2, text: 'Em Avaliação' },
        { value: 1, text: 'Aprovado' },
        { value: 4, text: 'Rejeitado' },
      ],

      selectOptionsFeedback: [
        { value: 2, text: 'Em Avaliação' },
        { value: 1, text: 'Aprovado' },
        { value: 3, text: 'Rejeitado' },
        { value: 4, text: 'Cancelado' },
      ],

      snowOption: {
        theme: 'snow',
        content: 'Texto',
        codeSnow: '',
      },
      creciFileSituation: '',
      documentFileSituation: '',
      facePhotoCreciFileSituation: '',
      facePhotoDocumentFileSituation: '',
    }
  },

  async mounted() {
    this.getDataUser()
  },
  methods: {
    validationForm() {
      this.register()
    },
    async register() {
      try {
        const data = {
          text: this.registerData.content,
          userBrokerProfileId: this.userId,
          situationProfile: this.registerData.selectedFeedback,
          creciFileSituation: this.registerData.selectedCreci,
          documentFileSituation: this.registerData.selectedDocument,
          facePhotoDocumentFileSituation: this.registerData.selectedFaceDocument,
          facePhotoCreciFileSituation: this.registerData.selectedFaceCreci,
        }

        await this.$http
          .post('/api/services/app/BrokerProfileValidations/Create', data, {
            timeout: 30000,
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          })
          .then(() => {
            this.msgSuccess()
            setTimeout(() => {
              this.$router.push({ name: 'deliberation' })
            }, 2700)
          })
          .catch(reason => reason)
      } catch (error) {
        this.msgError()
        this.errors = error.message
      }
    },
    async getDataUser() {
      const data = await this.$http
        .get(
          `/api/services/app/UserBrokerProfiles/GetUserBrokerProfileForValidation?Id=${this.userId}`,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })
      this.id = data.data.result.userBroker.userId

      const situationFiles = await this.$http
        .get(`/api/services/app/UserBrokerDatas/GetSituationFiles?userId=${this.id}`, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })

      this.setDataUser(data, situationFiles)
    },

    setDataUser(data, situationFiles) {
      // data user
      this.registerData.cityOperation = data.data.result.userBroker.cityOperation
      this.registerData.situation = data.data.result.userBroker.situation
      this.registerData.numberCpfCnpj = data.data.result.userBroker.user.numberCpfCnpj
      this.registerData.numberRg = data.data.result.userBroker.user.numberRg
      this.registerData.phoneNumber = data.data.result.userBroker.user.phoneNumber
      this.registerData.emailAddress = data.data.result.userBroker.user.emailAddress
      this.registerData.birthDate = this.frontEndDateFormat(data.data.result.userBroker.user.birthDate)
      this.registerData.typePersonName =
        data.data.result.userBroker.user.typePerson === 'F'
          ? 'Corretor'
          : 'Imobiliária'

      let attachments = []
      if(data.data.result.userBroker.user.typePerson === 'F') {
        attachments = [
          this.getCreciFile(data),
          this.getCreciSelf(data),
          this.getDocument(data),
          this.getDocumentSelfie(data),
        ]
      } else {
        attachments = [
          this.getCreciFile(data),
          this.getDocument(data),
        ]
      }
      this.attachments = attachments
      const {result} = situationFiles.data
      this.registerData.selectedCreci = result && result.creciFileSituation === 4 ? 2 : result.creciFileSituation
      this.registerData.selectedFaceCreci = result && result.facePhotoCreciFileSituation === 4 ? 2 : result.facePhotoCreciFileSituation
      this.registerData.selectedDocument = result && result.documentFileSituation === 4 ? 2 : result.documentFileSituation
      this.registerData.selectedFaceDocument = result && result.facePhotoDocumentFileSituation === 4 ? 2 : result.facePhotoDocumentFileSituation
      this.registerData.selectedFeedback = result && result.situationProfile === 3 ? 2 : result.situationProfile
    },

    getCreciFile(data){
      return  {
          bytes: data.data.result.creciFile ? data.data.result.creciFile.bytes : null,
          description: data.data.result.creciFile ? data.data.result.creciFile.description : null,
          dataType: data.data.result.creciFile ? data.data.result.creciFile.dataType : null,
          srcImg: data.data.result.creciFile ? `${data.data.result.creciFile.dataType},${data.data.result.creciFile.bytes}` : null,
          nameImg: 'CRECI',
          selectedName: 'selectedCreci',
          fileKey: 'creciFile',
          id: data.data.result.creciFile ? data.data.result.creciFile.id : null,
        }
    },

    getCreciSelf(data){
      return  {
          bytes: data.data.result.facePhotoCreciFile ? data.data.result.facePhotoCreciFile.bytes : null,
          description: data.data.result.facePhotoCreciFile ? data.data.result.facePhotoCreciFile.description : null,
          dataType: data.data.result.facePhotoCreciFile ? data.data.result.facePhotoCreciFile.dataType : null,
          srcImg: data.data.result.facePhotoCreciFile ? `${data.data.result.facePhotoCreciFile.dataType},${data.data.result.facePhotoCreciFile.bytes}` : null,
          nameImg: 'Self CRECI',
          selectedName: 'selectedFaceCreci',
          fileKey: 'facePhotoCreciFile',
          id: data.data.result.facePhotoCreciFile ? data.data.result.facePhotoCreciFile.id : null,
          show: data.data.result.userBroker.user.typePerson === 'F'
        }
    },

    getDocument(data){
      return {
          bytes: data.data.result.documentFile ? data.data.result.documentFile.bytes : null,
          description: data.data.result.documentFile ? data.data.result.documentFile.description : null,
          dataType: data.data.result.documentFile ? data.data.result.documentFile.dataType : null,
          srcImg: data.data.result.documentFile ? `${data.data.result.documentFile.dataType},${data.data.result.documentFile.bytes}` : null,
          nameImg: 'Documento',
          selectedName: 'selectedDocument',
          fileKey: 'documentFile',
          id: data.data.result.documentFile ? data.data.result.documentFile.id : null,
        }
    },

    getDocumentSelfie(data){
      return {
          bytes: data.data.result.facePhotoDocumentFile ? data.data.result.facePhotoDocumentFile.bytes : null,
          description: data.data.result.facePhotoDocumentFile ? data.data.result.facePhotoDocumentFile.description : null,
          dataType: data.data.result.facePhotoDocumentFile ? data.data.result.facePhotoDocumentFile.dataType : null,
          srcImg: data.data.result.facePhotoDocumentFile ? `${data.data.result.facePhotoDocumentFile.dataType},${data.data.result.facePhotoDocumentFile.bytes}` : null,
          nameImg: 'Self Documento',
          selectedName: 'selectedFaceDocument',
          fileKey: 'facePhotoDocumentFile',
          id: data.data.result.facePhotoDocumentFile ? data.data.result.facePhotoDocumentFile.id : null,
          show: data.data.result.userBroker.user.typePerson === 'F'
        }
    },

    frontEndDateFormat(v) {
      return Moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    openModalId: ({ codigo }) => `${codigo}`,

    setDataFile(data) {
      localStorage.setItem('fileDataView', JSON.stringify(data))
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import './../../sass/_variables.scss';
//---------- custon ----------//
.vue-form-wizard-deliberation-broker {
  background-color: #f8f8f8 !important;
  .wizard-navigation {
    .wizard-nav {
      padding: 0px 25px 0px 0px !important;
      li {
        padding-bottom: 0rem !important;
        .wizard-icon-circle {
          background-color: #f8f8f8 !important;
        }
        &.active {
          .wizard-icon-container {
            background-color: rgb(255 245 230) !important;
            .wizard-icon {
              font-size: 1.3rem !important;
              color: #ffb200 !important;
            }
          }
        }
      }
    }
  }
}
//---------- custon ----------//
.vertical-steps-deliberation-broker  {
  &.vue-form-wizard {
    .wizard-card-footer {
      margin-left: 218px !important;
    }
  }
}
  .mt-personnalite-2 {
    margin-top: 2px;
  }
// [dir='ltr'] .vertical-steps.vue-form-wizard .wizard-card-footer {
//   margin-left: 273px !important;
// }
  li.active a {
    background-color: #fff6e8 !important;
  }
  .wizard-icon-container {
    background-color: rgb(248 172 24 / 0%) !important;
  }
  .vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a .checked {
    box-shadow: 0 3px 6px 0 rgb(105 108 255 / 0%) !important;
  }
  .vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle.checked {
    background-color: rgb(248 172 24 / 0%) !important;
  }
  .vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li.active
  a
  .wizard-icon-circle
  .wizard-icon-container
  .wizard-icon {
    color: #f8ac18 !important;
    font-size: 1.3rem !important;
  }

  .stepTitle {
    padding-right: 12px !important;
  }
  .active {
    border-color: #fff6e800 !important;
    box-shadow: 0 4px 18px -4px #ffffff00 !important;
  }
  .wizard-icon {
    color: #5e5873 !important;
  }
  div.wizard-navigation li.active a span {
    color: #f8af26 !important;
    font-weight: 500 !important;
  }
  div.wizard-navigation li a span {
    color: #5e5873 !important;
    font-weight: 500 !important;
  }
  div.wizard-navigation .wizard-tab-content {
    margin-left: 35px;
  }
  .wizard-icon-circle {
    margin-right: 0px !important;
  }
  .vertical-steps.vue-form-wizard .wizard-navigation .wizard-nav {
    padding-top: 0px !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li {
    padding-bottom: 0.2rem !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle {
    border: none;
    background-color: #ededed00 !important;
    border-radius: 6px;
  }
// .vue-form-wizard .wizard-card-footer {
//   margin-left: 273px !important;
// }
.detailing {
  &__cursor {
    cursor: pointer;
  }

  &__dp-center {
    display: block;
  }

  box-sizing: border-box;
  padding: 10px 20px;

  & span,
  & td {
    color: $color-black;
  }

  &__ft-size-10 {
    font-size: 10px;
  }

  &__title {
    font-family: $source-sans-pro;
    color: $color-black;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 900px) {
      flex-direction: row;
    }
  }

  &__document-card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

    border-radius: 9px;
    box-sizing: border-box;
    width: 170px;
    padding: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 900px) {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  &__feedback-description {
    font-size: 16px;
    line-height: 19px;
    color: #a6adba;
    width: 80%;
    @media (min-width: 900px) {
      max-width: 600px;
    }
  }

  .ql-container {
    min-height: 200px;
  }
}
</style>
