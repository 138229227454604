<template>
  <b-row class="broker-detailed">
    <deliberation-broker />
  </b-row>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import DeliberationBroker from './broker/deliberation-account-editing/AccountSetting.vue'

export default {
  components: {
    BRow,
    DeliberationBroker,
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
body {
    background-color: #f8f8f8 !important;
}
// .broker-detailed {
//   .vue-form-wizard .clearfix:after {
//     display: none;
//   }

//   .vertical-steps.vue-form-wizard .wizard-card-footer {
//     display: flex;
//     justify-content: space-between;
//     float: none;
//   }
//   .vertical-steps.vue-form-wizard .wizard-navigation .wizard-nav {
//     padding-right: 0px;
//   }
//   .active {
//     border-color: #fff6e8;
//     box-shadow: 0 4px 18px -4px rgb(255 255 255);
//   }
//   .nav-pills .nav-link.active,
//   .nav-pills .show > .nav-link {
//     background-color: #fff6e8;
//   }
//   .nav-pills .nav-link.active,
//   .nav-pills .show > .nav-link {
//     color: #f8af26;
//   }
//   .vue-form-wizard .clearfix:after {
//     display: none;
//   }
//   .vertical-steps.vue-form-wizard .wizard-card-footer {
//     display: flex;
//     justify-content: space-between;
//     float: none;
//   }

//   @media (min-width: 767.98px) {
//     .vertical-steps.vue-form-wizard .wizard-tab-content,
//     .vertical-steps.vue-form-wizard .wizard-card-footer {
//       margin-left: 15px;
//       width: 70vw;
//     }

//     .steps-transparent.vue-form-wizard {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-end;
//     }
//   }
//   @media (min-width: 1200px) {
//     .vertical-steps.vue-form-wizard .wizard-tab-content,
//     .vertical-steps.vue-form-wizard .wizard-card-footer {
//       margin-left: 44px;
//       width: 60vw;
//     }
//   }
// }
</style>
